import { useState, useEffect, useRef } from "react";
import {
  FaTimes,
  FaComment,
  FaPaperPlane,
  FaMicrophone,
  FaRobot,
  FaUserCircle,
  FaEllipsisV,
  FaEdit,
  FaTrash,
} from "react-icons/fa";
import ChatLogic from "./ChatLogic";

const ChatView = () => {
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [volume, setVolume] = useState(0);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editText, setEditText] = useState("");
  const [activeMenu, setActiveMenu] = useState(null);

  const {
    messages = [], // Default to empty array if messages is undefined
    handleSendMessage,
    currentQuestions = [], // Default to empty array if currentQuestions is undefined
    isTyping,
    editMessage,
    deleteMessage,
  } = ChatLogic();

  const recognitionRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const messageInputRef = useRef(null);

  useEffect(() => {
    const chatBox = document.getElementById("chat-messages");
    if (chatBox) {
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messages]);

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activeMenu && !event.target.closest(".message-menu-container")) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenu]);

  // Focus on input when editing
  useEffect(() => {
    if (editingMessageId && messageInputRef.current) {
      messageInputRef.current.focus();
    }
  }, [editingMessageId]);

  // Voice Recognition with Real-Time Intensity Effect
  const handleVoiceInput = async () => {
    if (
      !("webkitSpeechRecognition" in window || "SpeechRecognition" in window)
    ) {
      alert("Your browser does not support voice recognition!");
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition;

    recognition.continuous = false;
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 1;

    recognition.onstart = async () => {
      setIsListening(true);
      await startVoiceEffect();
    };

    recognition.onend = () => {
      setIsListening(false);
      stopVoiceEffect();
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
      setIsListening(false);
      stopVoiceEffect();
      alert("Speech recognition failed. Please try again.");
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setMessage(transcript);
    };

    recognition.start();
  };

  const startVoiceEffect = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContextRef.current.createAnalyser();
      analyser.fftSize = 32;
      analyserRef.current = analyser;

      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyser);

      const dataArray = new Uint8Array(analyser.frequencyBinCount);

      const updateVolume = () => {
        analyser.getByteFrequencyData(dataArray);
        const avgVolume =
          dataArray.reduce((sum, val) => sum + val, 0) / dataArray.length;
        setVolume(avgVolume);
        if (isListening) requestAnimationFrame(updateVolume);
      };
      updateVolume();
    } catch (error) {
      console.error("Voice effect error:", error);
      alert(
        "Microphone access denied. Please allow access to use voice input."
      );
    }
  };

  const stopVoiceEffect = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setVolume(0);
  };

  // Message menu controls
  const toggleMenu = (messageId) => {
    setActiveMenu(activeMenu === messageId ? null : messageId);
  };

  const handleEditStart = (messageId, text) => {
    setEditingMessageId(messageId);
    setEditText(text);
    setActiveMenu(null);
  };

  const handleEditCancel = () => {
    setEditingMessageId(null);
    setEditText("");
  };

  const handleEditSave = (messageId) => {
    if (editText.trim()) {
      editMessage(messageId, editText);
      setEditingMessageId(null);
      setEditText("");
    }
  };

  const handleDeleteMessage = (messageId) => {
    deleteMessage(messageId);
    setActiveMenu(null);
  };

  return (
    <div className="fixed bottom-5 right-5">
      {isOpen ? (
        <div className="w-80 h-[450px] bg-white shadow-lg rounded-xl flex flex-col">
          {/* Header */}
          {/* Header */}
          <div className="flex items-center p-4 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-t-xl shadow-md">
            <img
              src="https://i.postimg.cc/28s8QTSm/480837813-2360150204321719-7445404526734507101-n.jpg" // Replace with your avatar
              alt="MD AL AMIN"
              className="w-10 h-10 rounded-full border-2 border-white mr-3"
            />
            <div className="flex-1">
              <h3 className="text-sm">Chat with</h3>
              <h2 className="text-lg font-semibold">MD AL AMIN</h2>
            </div>
            <button className="text-white">
              <FaEllipsisV className="text-lg" />
            </button>
            <button onClick={() => setIsOpen(false)}>
              <FaTimes className="text-xl" />
            </button>
          </div>

          {/* Chat Messages */}
          <div
            id="chat-messages"
            className="flex-1 overflow-y-auto p-3 space-y-2"
          >
            {messages.map((item) => (
              <div
                key={item.id}
                className={`flex ${
                  item.sender === "user" ? "justify-end" : "justify-start"
                } relative group`}
              >
                {item.sender === "bot" && (
                  <FaRobot className="text-gray-500 text-xl mr-2 mt-1 shrink-0" />
                )}

                {/* Edit Mode */}
                {editingMessageId === item.id ? (
                  <div className="p-2 bg-yellow-100 rounded-lg max-w-[70%] flex flex-col">
                    <input
                      type="text"
                      className="p-1 border rounded w-full mb-2 focus:outline-none focus:border-blue-500"
                      value={editText}
                      onChange={(e) => setEditText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEditSave(item.id);
                        if (e.key === "Escape") handleEditCancel();
                      }}
                      ref={messageInputRef}
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        className="text-xs px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                        onClick={handleEditCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
                        onClick={() => handleEditSave(item.id)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Normal Message */}
                    <div
                      className={`p-2 rounded-lg max-w-[70%] ${
                        item.sender === "user"
                          ? "bg-blue-200 text-black"
                          : "bg-gray-200 text-black"
                      }`}
                    >
                      {item.text}
                      {item.edited && (
                        <span className="text-xs text-gray-500 ml-1">
                          (edited)
                        </span>
                      )}
                      {item.sender === "bot" &&
                        isTyping &&
                        item.id === messages[messages.length - 1].id && (
                          <span className="animate-pulse ml-1">...</span>
                        )}
                    </div>

                    {/* Options Menu for User Messages Only */}
                    {item.sender === "user" && (
                      <div className="message-menu-container">
                        <button
                          className="text-gray-400 hover:text-gray-600 opacity-0 group-hover:opacity-100 ml-1 transition-opacity"
                          onClick={() => toggleMenu(item.id)}
                        >
                          <FaEllipsisV />
                        </button>
                        {activeMenu === item.id && (
                          <div className="absolute right-0 mt-6 bg-white shadow-md rounded-md z-10 py-1 w-24">
                            <button
                              className="w-full text-left px-3 py-1 text-sm hover:bg-gray-100 flex items-center"
                              onClick={() =>
                                handleEditStart(item.id, item.text)
                              }
                            >
                              <FaEdit className="mr-2" /> Edit
                            </button>
                            <button
                              className="w-full text-left px-3 py-1 text-sm text-red-500 hover:bg-gray-100 flex items-center"
                              onClick={() => handleDeleteMessage(item.id)}
                            >
                              <FaTrash className="mr-2" /> Delete
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

                {item.sender === "user" && !editingMessageId && (
                  <FaUserCircle className="text-blue-500 text-xl ml-2 mt-1 shrink-0" />
                )}
              </div>
            ))}
          </div>

          {/* Suggested Questions */}
          {currentQuestions.length > 0 && (
            <div className="p-3 border-t border-gray-300">
              <h4 className="text-sm text-gray-600 mb-2">Quick questions:</h4>
              <div className="flex flex-wrap gap-2">
                {currentQuestions.map((question) => (
                  <button
                    key={question.id}
                    className="bg-gray-300 text-sm px-3 py-1 rounded-md hover:bg-gray-400"
                    onClick={() => handleSendMessage(question.text)}
                  >
                    {question.text}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Input Field with Voice Effect */}
          <div className="p-3 border-t border-gray-300">
            <div className="flex items-center relative">
              <input
                type="text"
                className="flex-1 p-2 border rounded-md focus:outline-none pr-10"
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && message.trim()) {
                    handleSendMessage(message);
                    setMessage("");
                  }
                }}
              />
              {/* Voice Effect Bars */}
              {isListening && (
                <div className="absolute right-16 flex items-center space-x-1">
                  {[...Array(4)].map((_, i) => (
                    <div
                      key={i}
                      className="w-1 bg-green-500 rounded"
                      style={{
                        height: `${Math.max(
                          2,
                          volume / 2 + (i % 2 === 0 ? 5 : 10)
                        )}px`,
                        transition: "height 0.1s",
                      }}
                    />
                  ))}
                </div>
              )}
              {/* Send Button */}
              <button
                className="bg-blue-600 text-white p-2 ml-2 rounded-full hover:bg-blue-700 disabled:bg-gray-400"
                onClick={() => {
                  handleSendMessage(message);
                  setMessage("");
                }}
                disabled={!message.trim() || isTyping}
              >
                <FaPaperPlane />
              </button>
              {/* Microphone Button */}
              <button
                className={`p-2 ml-2 rounded-full ${
                  isListening
                    ? "bg-red-600 animate-pulse"
                    : "bg-green-600 hover:bg-green-700"
                } text-white`}
                onClick={handleVoiceInput}
              >
                <FaMicrophone />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button
          className="w-14 h-14 bg-blue-600 text-white flex justify-center items-center rounded-full shadow-lg hover:bg-blue-700"
          onClick={() => setIsOpen(true)}
        >
          <FaComment className="text-2xl" />
        </button>
      )}
    </div>
  );
};

export default ChatView;
