import { useState, useEffect } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import * as tf from "@tensorflow/tfjs";
import * as use from "@tensorflow-models/universal-sentence-encoder";

// ✅ Set the worker script URL for PDF.js
GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js";

const pdfUrl = "/nisan.pdf";

// ✅ Load Universal Sentence Encoder (AI Model)
let useModel = null;

const loadUSEModel = async () => {
  if (!useModel) {
    useModel = await use.load();
  }
};

// ✅ Improved PDF text extraction with better structure handling
const extractTextFromPDF = async () => {
  try {
    const pdf = await getDocument(pdfUrl).promise;
    let allContent = [];
    
    // Extract all content with structural information
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const textContent = await page.getTextContent();
      const pageText = textContent.items.map((item) => item.str).join(" ");
      
      allContent.push({
        page: i + 1,
        text: pageText
      });
    }
    
    // Process the content to identify chapters and sections
    let structuredContent = [];
    let currentChapter = null;
    let currentSection = null;
    
    allContent.forEach(pageContent => {
      // Match chapter and section headers
      const chapterMatches = [...pageContent.text.matchAll(/Chapter\s+(\d+)\s*:\s*([^\n.]+)/g)];
      const sectionMatches = [...pageContent.text.matchAll(/Section\s+(\d+)\s*:\s*([^\n.]+)/g)];
      
      // Extract paragraphs (content between headers)
      let paragraphs = pageContent.text.split(/\n\s*\n/).filter(p => p.trim().length > 0);
      
      // Process chapter matches
      chapterMatches.forEach(match => {
        currentChapter = {
          type: 'chapter',
          number: parseInt(match[1]),
          title: match[2].trim(),
          content: match[0],
          page: pageContent.page
        };
        structuredContent.push(currentChapter);
      });
      
      // Process section matches
      sectionMatches.forEach(match => {
        currentSection = {
          type: 'section',
          number: parseInt(match[1]),
          title: match[2].trim(),
          chapterNumber: currentChapter?.number,
          content: match[0],
          page: pageContent.page
        };
        structuredContent.push(currentSection);
      });
      
      // Process paragraphs that aren't headers
      paragraphs.forEach(paragraph => {
        if (paragraph.length > 50 && 
            !paragraph.match(/Chapter\s+\d+\s*:/) && 
            !paragraph.match(/Section\s+\d+\s*:/)) {
          structuredContent.push({
            type: 'paragraph',
            content: paragraph,
            chapterNumber: currentChapter?.number,
            sectionNumber: currentSection?.number,
            page: pageContent.page
          });
        }
      });
    });
    
    console.log("🔍 Structured Content:", structuredContent);
    return structuredContent;
  } catch (error) {
    console.error("❌ Error extracting text:", error);
    return [];
  }
};

// ✅ Convert Text to Vector Using Universal Sentence Encoder (AI)
const encodeText = async (text) => {
  await loadUSEModel();
  const embeddings = await useModel.embed([text]); // Convert text into vector
  return embeddings.arraySync()[0]; // Convert tensor to array
};

// ✅ Function to Calculate Cosine Similarity
const cosineSimilarity = (vec1, vec2) => {
  let dotProduct = vec1.reduce((sum, val, i) => sum + val * vec2[i], 0);
  let magnitude1 = Math.sqrt(vec1.reduce((sum, val) => sum + val ** 2, 0));
  let magnitude2 = Math.sqrt(vec2.reduce((sum, val) => sum + val ** 2, 0));
  return dotProduct / (magnitude1 * magnitude2);
};

// ✅ Extract relevant information from user query
const extractQueryInfo = (query) => {
  // Check for specific section or chapter requests
  const sectionMatch = query.match(/section\s+(\d+)/i);
  const chapterMatch = query.match(/chapter\s+(\d+)/i);
  
  return {
    requestedSection: sectionMatch ? parseInt(sectionMatch[1]) : null,
    requestedChapter: chapterMatch ? parseInt(chapterMatch[1]) : null,
    rawQuery: query
  };
};

// ✅ Intelligent content search combining direct matching and semantic search
const findRelevantContent = async (query, pdfContent) => {
  const queryInfo = extractQueryInfo(query);
  let results = [];
  
  // Direct section/chapter match if explicitly requested
  if (queryInfo.requestedSection) {
    const sectionContents = pdfContent.filter(
      item => item.type === 'section' && item.number === queryInfo.requestedSection
    );
    
    // Add paragraphs from the requested section
    const sectionParagraphs = pdfContent.filter(
      item => item.type === 'paragraph' && item.sectionNumber === queryInfo.requestedSection
    );
    
    if (sectionContents.length > 0 || sectionParagraphs.length > 0) {
      return {
        type: 'direct',
        items: [...sectionContents, ...sectionParagraphs],
        query: queryInfo.rawQuery
      };
    }
  }
  
  if (queryInfo.requestedChapter) {
    const chapterContents = pdfContent.filter(
      item => item.type === 'chapter' && item.number === queryInfo.requestedChapter
    );
    
    // Add sections and paragraphs from the requested chapter
    const chapterSections = pdfContent.filter(
      item => (item.type === 'section' || item.type === 'paragraph') && 
              item.chapterNumber === queryInfo.requestedChapter
    );
    
    if (chapterContents.length > 0 || chapterSections.length > 0) {
      return {
        type: 'direct',
        items: [...chapterContents, ...chapterSections],
        query: queryInfo.rawQuery
      };
    }
  }
  
  // Semantic search for all other queries
  const queryEmbedding = await encodeText(queryInfo.rawQuery);
  const contentPromises = pdfContent.map(async (item) => {
    // Skip items with very short content
    if (!item.content || item.content.length < 20) return null;
    
    const contentEmbedding = await encodeText(item.content);
    const similarity = cosineSimilarity(queryEmbedding, contentEmbedding);
    
    return { 
      ...item, 
      similarity 
    };
  });
  
  // Process all embeddings and similarity calculations
  const contentWithSimilarity = (await Promise.all(contentPromises))
    .filter(item => item !== null)
    .sort((a, b) => b.similarity - a.similarity)
    .slice(0, 3); // Get top 3 most relevant items
  
  return {
    type: 'semantic',
    items: contentWithSimilarity,
    query: queryInfo.rawQuery
  };
};

// ✅ Format content for response
const formatContentForResponse = (searchResults) => {
  if (!searchResults || !searchResults.items || searchResults.items.length === 0) {
    return "❌ I couldn't find relevant information about Nissan based on your query. Could you please try rephrasing your question?";
  }
  
  let response = "";
  
  if (searchResults.type === 'direct') {
    // For direct section/chapter requests
    const chapters = searchResults.items.filter(item => item.type === 'chapter');
    const sections = searchResults.items.filter(item => item.type === 'section');
    const paragraphs = searchResults.items.filter(item => item.type === 'paragraph');
    
    if (chapters.length > 0) {
      response += `📚 **Chapter ${chapters[0].number}: ${chapters[0].title}**\n\n`;
    }
    
    if (sections.length > 0) {
      response += `📑 **Section ${sections[0].number}: ${sections[0].title}**\n\n`;
    }
    
    // Add paragraphs content
    paragraphs.forEach(para => {
      response += `${para.content}\n\n`;
    });
    
  } else {
    // For semantic search results
    response += `🔍 **Here's what I found about "${searchResults.query}":**\n\n`;
    
    searchResults.items.forEach((item, index) => {
      let contextInfo = "";
      if (item.type === 'chapter') {
        contextInfo = `Chapter ${item.number}: ${item.title}`;
      } else if (item.type === 'section') {
        contextInfo = `Section ${item.number}: ${item.title}`;
        if (item.chapterNumber) {
          contextInfo += ` (Chapter ${item.chapterNumber})`;
        }
      } else if (item.chapterNumber || item.sectionNumber) {
        if (item.chapterNumber) contextInfo += `Chapter ${item.chapterNumber}`;
        if (item.sectionNumber) contextInfo += ` Section ${item.sectionNumber}`;
      }
      
      response += `${index + 1}. ${contextInfo ? `[${contextInfo}] ` : ''}${item.content}\n\n`;
    });
  }
  
  // Add source info
  const pages = [...new Set(searchResults.items.map(item => item.page))].sort((a, b) => a - b);
  if (pages.length > 0) {
    response += `📄 Source: Page${pages.length > 1 ? 's' : ''} ${pages.join(', ')}`;
  }
  
  return response;
};

// ✅ AI Chat Logic
const ChatLogic = () => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pdfContent, setPdfContent] = useState([]); // Store extracted PDF content

  // ✅ Load and cache PDF content on component mount
  useEffect(() => {
    const loadPDFContent = async () => {
      const content = await extractTextFromPDF();
      setPdfContent(content);
    };
    loadPDFContent();
  }, []);

  const handleSendMessage = async (message) => {
    if (!message.trim() || isProcessing) return;

    const userMessage = { text: message, sender: "user", id: Date.now() };
    setMessages((prev) => [...prev, userMessage]);

    setIsProcessing(true);

    const sendBotReply = (botReplyText) => {
      const botReply = {
        text: botReplyText,
        sender: "bot",
        id: Date.now() + 1,
      };

      setIsTyping(true);
      let i = 0;
      const typingInterval = setInterval(() => {
        if (i < botReply.text.length) {
          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (
              lastMessage.sender === "bot" &&
              lastMessage.id === botReply.id
            ) {
              return [
                ...prev.slice(0, -1),
                { ...lastMessage, text: botReply.text.slice(0, i + 1) },
              ];
            } else {
              return [
                ...prev,
                { ...botReply, text: botReply.text.slice(0, i + 1) },
              ];
            }
          });
          i++;
        } else {
          clearInterval(typingInterval);
          setIsTyping(false);
          setIsProcessing(false);
        }
      }, 30); // Faster typing speed
    };

    // ✅ Check for conversational queries
    const conversationalQueries = ["hello", "hi", "hey", "thanks", "thank you"];
    if (conversationalQueries.some(q => message.toLowerCase().includes(q))) {
      sendBotReply("👋 Hello! I'm your Nissan information assistant. Feel free to ask me anything about Nissan vehicles, features, or specifications. You can also request specific chapters or sections from the manual.");
      return;
    }

    try {
      // Process the user's query
      if (pdfContent.length === 0) {
        sendBotReply("⏳ I'm still loading the Nissan manual. Please try again in a moment.");
        return;
      }

      const searchResults = await findRelevantContent(message, pdfContent);
      const formattedResponse = formatContentForResponse(searchResults);
      
      sendBotReply(formattedResponse);
    } catch (error) {
      console.error("Error processing query:", error);
      sendBotReply("❌ I encountered an error while processing your request. Please try again.");
    }
  };

  // ✅ Edit Message Function
  const editMessage = (messageId, newText) => {
    setMessages((prev) =>
      prev.map((message) =>
        message.id === messageId
          ? { ...message, text: newText, edited: true }
          : message
      )
    );
  };

  // ✅ Delete Message Function
  const deleteMessage = (messageId) => {
    setMessages((prev) => prev.filter((message) => message.id !== messageId));
  };

  return {
    messages,
    handleSendMessage,
    isTyping,
    editMessage,
    deleteMessage,
  };
};

export default ChatLogic;